import { Button, ProgressBar, utils } from '@makeship/core';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useEffect, useContext, useRef } from 'react';
import styled, { css } from 'styled-components';
import posthog from 'posthog-js';
import { Element as ScrollElement } from 'react-scroll';
import Cookies from 'js-cookie';
import config from '../../../config.json';
import { useStore } from '../../store';
import { addProduct, showCart } from '../../store/store.actions';
import { productMOQ, ProductStage, ProductTag, CartEventLocations, BadgeText } from '../../types/common';
import {
  analyticsTrackAddToCart,
  analyticsTrackItemSelectedFromList,
  analyticsTrackViewCart,
} from '../../utils/analytics';
import {
  getBadgeText,
  getStage,
  hasSale,
  parseMetafields,
  hasProductTag,
  getProductIDFromShopifyGID,
  calculateTotalInventorySold,
  getEvergreenInventoryState,
  isProductSoldOut,
  shouldShowProgressBar,
  isEligibleForTimer,
  getProgressBarProps,
} from '../../utils/product';
import Badge from '../Badge';
import Countdown from '../Countdown';
import { Caption, CaptionStyles, P2Styles, S2, S3, S3Styles } from '../Typography';
import SpamFlaggingContext from '../../context/spamFlagging';
import { formatNumberStringWithComma } from '../../utils/accounting';
import { shouldDisplayBadge } from '../../utils/collections';
import { getPetitionPledgeCountByExternalProductID } from '../../api/storefront/petition';
import { CookieConsent } from '../../types/cookies';
import { isHolidayHideawayProduct } from '../../utils/bfcm';

const { elements } = config;

type ProductCardProps = {
  product: Shopify.Product;
  isOnMainPage?: boolean;
  isOnVinylCollectionPage?: boolean;
  titleForTracking?: string;
  query?: string;
};

type BadgesContent = {
  [key: string]: BadgeText;
};

const badgesContent: BadgesContent = {
  [ProductStage.ComingSoon]: BadgeText.ComingSoon,
  [ProductStage.Past]: BadgeText.PastCampaign,
  [ProductStage.PetitionSuccess]: BadgeText.PetitionSuccess,
};

const AddToCartButton = styled(Button.Primary)`
  position: absolute !important;
  bottom: 0;
  left: 0;
  width: 100%;
  visibility: hidden;
  // padding: 10px 0;
`;

const ImageWrapper = styled.div<{ hide?: boolean; isPetition: boolean }>`
  position: relative;
  width: 100%;
  margin-bottom: ${({ isPetition }) => (isPetition ? `2px` : `8px`)};
  padding: 7px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    padding: 0;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  cursor: pointer;
  position: relative;
  padding: 10px;
  text-align: left;

  &:hover {
    ${AddToCartButton} {
      visibility: visible;
    }
    ${ImageWrapper} {
      padding: 0;
    }
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    &:hover {
      ${AddToCartButton} {
        visibility: hidden;
      }
    }
  }
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 4px;
`;

const LinkWrapper = styled.a``;

const AddDots = css`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const Title = styled(S2)<{ invertColor: boolean | undefined }>`
  ${AddDots}
  ${({ invertColor, theme }) => (invertColor ? `color: ${theme.colors.neutral1};` : ``)};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    ${S3Styles}
    ${({ invertColor, theme }) => (invertColor ? `color: ${theme.colors.neutral1};` : ``)};
  }
`;

const PetitionTitle = styled(S2)`
  color: ${({ theme }) => `${theme.colors.prelaunchPurple}`};
`;

const ProductDetailWrapper = styled.div<{
  invertColor: boolean | undefined;
  isComingSoon?: boolean;
  isPetition?: boolean;
}>`
  ${P2Styles}
  ${AddDots}
  margin: ${({ isComingSoon, isPetition }) => (isComingSoon ? `0 0 8px 0` : isPetition ? `0 0 6px 0` : `0 0 24px 0`)};
  color: ${({ theme }) => utils.hexToRGBA(theme.colors.neutral7, theme.alpha.dark)};
  ${({ invertColor, theme }) => (invertColor ? `color: ${theme.colors.neutral1};` : ``)};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    ${CaptionStyles}
    ${({ invertColor, theme }) => (invertColor ? `color: ${theme.colors.neutral1};` : ``)};
  }
`;

const StatsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Stats = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 2px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    flex-direction: column;
  }
`;

const StatsText = styled(Caption)<{ invertColor: boolean | undefined }>`
  ${({ invertColor, theme }) => (invertColor ? `color: ${theme.colors.neutral1};` : ``)};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    margin-bottom: 2px;
  }
`;

const DateText = styled(S3)<{ invertColor: boolean | undefined }>`
  ${({ invertColor, theme }) => (invertColor ? `color: ${theme.colors.neutral1};` : ``)};
`;

const BlueImage = styled(Image)`
  filter: invert(10%) sepia(90%) saturate(5268%) hue-rotate(270deg) brightness(65%) contrast(155%);
`;

const StyledBadge = styled(Badge)`
  position: absolute;
  top: 0;
  right: 0;
`;

const CountdownWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const ShippingAvailability = styled(S2)`
  color: ${({ theme }) => theme.colors.primary};
`;

const ShippingAvailabilityWrapper = styled.div`
  gap: 8px;
  display: flex;
  align-items: center;
  margin-top: 8px;
`;

const StyledImage = styled(Image)<{ isPetition?: boolean }>`
  border-radius: 0px 8px 8px 8px;
  ${({ isPetition, theme }) =>
    !isPetition &&
    `
    background-color: ${utils.hexToRGBA(theme.colors.cloud, theme.alpha.light)};
  `}
`;

const StyledImageBorder = styled.div<{ isPetition?: boolean }>`
  display: flex;
  border-radius: 0px 8px 8px 8px;
  ${({ isPetition, theme }) =>
    !isPetition &&
    `
      border: 1px solid ${utils.hexToRGBA(theme.colors.midnight, theme.alpha.extraLight)};
    `}
`;

const GITDTagWrapper = styled.div`
  position: absolute;
  top: 16px;
  left: 16px;
  max-width: 50px;
  z-index: 1;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    max-width: 28px;
  }
`;

const renderDateStats = (
  stage: string,
  invertColor: boolean | undefined,
  { enddate, launch, petitionEndDate }: Metafields,
): React.ReactNode => {
  if (stage === 'comingsoon') {
    return (
      <CountdownWrapper>
        <IconWrapper>🗓️</IconWrapper>
        <DateText invertColor={invertColor}>
          {launch && utils.parseDate(launch).toDate().toLocaleString('default', { month: 'long', day: 'numeric' })}
        </DateText>
      </CountdownWrapper>
    );
  }
  if (stage === 'petition' && petitionEndDate && utils.getDayDiffFrom(petitionEndDate) >= 0) {
    return (
      <CountdownWrapper>
        <IconWrapper>🕓</IconWrapper>
        <DateText data-testid="product-card-date-text" invertColor={invertColor}>
          <Countdown date={petitionEndDate} />
        </DateText>
      </CountdownWrapper>
    );
  }
  if (stage === ProductStage.PetitionSuccess) {
    return <></>;
  }
  if (enddate && utils.getDayDiffFrom(enddate) >= 0) {
    return (
      <CountdownWrapper>
        <IconWrapper>🕓</IconWrapper>
        <DateText data-testid="product-card-date-text" invertColor={invertColor}>
          <Countdown date={enddate} />
        </DateText>
      </CountdownWrapper>
    );
  }
  return <></>;
};

const ProductCard: React.FC<ProductCardProps> = ({
  product,
  isOnMainPage,
  isOnVinylCollectionPage,
  titleForTracking,
  query,
}: ProductCardProps) => {
  const { state, dispatch } = useStore();
  const { incrementSpamCounter, isSpam } = useContext(SpamFlaggingContext);
  const [totalSold, setTotalSold] = React.useState<number>(0);
  const metafields = parseMetafields(product.metafields);
  const stage = getStage(product.tags);
  const sale = hasSale(product);
  const isLimited = hasProductTag(product.tags, ProductTag.Limited);
  const isEvergreen = hasProductTag(product.tags, ProductTag.Evergreen);
  const isPetition = hasProductTag(product.tags, ProductTag.Petition);
  const isSuccessfulPetitionNotLive = getStage(product.tags) === ProductStage.PetitionSuccess;
  const inventoryState = getEvergreenInventoryState(product);
  const moq = Number(metafields.moq) || productMOQ[product.productType] || config.defaultMOQ;
  const isSoldOut = isProductSoldOut(product, totalSold);
  const invertColor = isOnMainPage && stage === ProductStage.ComingSoon;
  const badgeText = getBadgeText(sale, stage, inventoryState) || badgesContent[stage];
  const hideUnits = hasProductTag(product.tags, ProductTag.HideUnits);
  const hasManyVariants = product.variants.edges.length > 1;

  // GITD2024
  const isGITD = hasProductTag(product.tags, ProductTag.GITD);

  const { asPath } = useRouter();

  const cardRef = useRef(null);

  const getStatsText = (): React.ReactNode => {
    if (isLimited) {
      if (totalSold < 0) {
        return `${formatNumberStringWithComma(moq)}/${formatNumberStringWithComma(moq)} Sold`;
      }
      return `${formatNumberStringWithComma(moq - totalSold)}/${formatNumberStringWithComma(moq)} Sold`;
    }
    if (hideUnits && totalSold >= moq) {
      return `100%+ Funded`;
    }
    if (isPetition || isSuccessfulPetitionNotLive) {
      return `${formatNumberStringWithComma(Math.floor((totalSold / moq) * 100))}% of goal`;
    }

    return `${formatNumberStringWithComma(Math.floor((totalSold / moq) * 100))}% Funded`;
  };

  const setVisitedCard = () => {
    const cookieConsentAccepted = Cookies.get(CookieConsent.Functional) === 'true';
    if (cookieConsentAccepted) {
      sessionStorage.setItem('visitedProductCard', product.title);
    }
  };

  const handleVisible = async (product: Shopify.Product) => {
    const shopifyProductInventory = Math.abs(product.totalInventory ?? 0);
    if (isPetition || isSuccessfulPetitionNotLive) {
      const pledgeCount = await getPetitionPledgeCountByExternalProductID(getProductIDFromShopifyGID(product.id))
        .then((response) => response.data)
        .catch(() => shopifyProductInventory);
      setTotalSold(pledgeCount ?? shopifyProductInventory);
    } else {
      const currentTotalSold = await calculateTotalInventorySold(product);
      setTotalSold(currentTotalSold ?? shopifyProductInventory);
    }
  };

  const isOnProductCard = true;
  const { isGradient, percentage, funded, height } = getProgressBarProps(product, isOnProductCard, totalSold);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            handleVisible(product);
            observer.unobserve(entry.target);
          }
        });
      },
      {
        threshold: 0.1,
        rootMargin: '20%',
      },
    );

    if (cardRef.current) {
      observer.observe(cardRef.current);
    }

    return () => {
      if (cardRef.current) {
        observer.unobserve(cardRef.current);
      }
    };
  }, [product.id]);

  const handleProductCardClick = () => {
    setVisitedCard();
    productCardTrackingClick();
  };

  const productCardTrackingClick = () => {
    posthog.capture('product_card_click', {
      product_title: product.title,
      product_id: getProductIDFromShopifyGID(product.id),
      product_stage: stage,
      product_tags: product.tags,
      location: asPath,
    });
  };

  return (
    <Container
      data-gtm-name="product-card-container"
      ref={cardRef}
      onClick={() => {
        titleForTracking === 'search' &&
          posthog.capture('search_result_click', {
            product_title: product.title,
            product_id: getProductIDFromShopifyGID(product.id),
            search_query: query?.trim(),
            location: 'search_results_page',
            product_stage: stage,
            product_tags: product.tags,
          });
        analyticsTrackItemSelectedFromList(product, titleForTracking, state.user);
      }}
    >
      <ImageWrapper hide={stage === ProductStage.ComingSoon} isPetition={isPetition}>
        <Link href={`/products/${product.handle}`} passHref>
          <LinkWrapper data-gtm-name="product-card-anchor" onClick={handleProductCardClick}>
            {!invertColor ? (
              <StyledImageBorder isPetition={isPetition}>
                {
                  // GITD2024
                  isGITD && (
                    <GITDTagWrapper>
                      <Image
                        src="/assets/gitd-2024/gitd_tag.png"
                        alt="Glow in the Dark"
                        width={50}
                        height={50}
                        quality={90}
                        unoptimized
                      />
                    </GITDTagWrapper>
                  )
                }
                <StyledImage
                  src={product.images.edges[0]?.node.transformedSrc || '/assets/default_product_asset.png'}
                  alt={product.images.edges[0]?.node.altText || 'product image'}
                  width={500}
                  height={500}
                  quality={90}
                  unoptimized
                  data-testid="product-card-image"
                  isPetition={isPetition}
                />
              </StyledImageBorder>
            ) : (
              <BlueImage
                src={product.images.edges[0]?.node.transformedSrc || '/assets/default_product_asset.png'}
                alt={product.images.edges[0]?.node.altText || 'product image'}
                width={500}
                height={500}
                quality={90}
                unoptimized
                data-testid="product-card-image"
              />
            )}

            {!isOnMainPage && stage !== ProductStage.ComingSoon && !isOnVinylCollectionPage && (
              <StyledBadge text={badgeText} isActive={shouldDisplayBadge(stage, sale, inventoryState)} />
            )}
          </LinkWrapper>
        </Link>
        <ScrollElement name={product.title} />
        {stage === ProductStage.Live && product.availableForSale && !isSoldOut && !hasManyVariants && (
          <AddToCartButton
            id={elements.addToCartButton}
            data-testid="add-to-cart-button"
            onClick={() => {
              addProduct(state.cart, product, 1, product.variants.edges[0]?.node).then((action) => dispatch(action));
              dispatch(showCart());
              incrementSpamCounter();
              if (!isSpam) {
                analyticsTrackAddToCart(
                  product,
                  1,
                  state.user,
                  CartEventLocations.ProductThumbnail,
                  product.variants.edges[0]?.node,
                  metafields.campaignID,
                );
                analyticsTrackViewCart(
                  state.cart.checkout?.lineItems.edges,
                  state.cart.checkout?.totalPriceV2.amount,
                  state.user,
                  CartEventLocations.CartPopout,
                );
              }
            }}
          >
            Add to Cart
          </AddToCartButton>
        )}
      </ImageWrapper>
      <Link href={`/products/${product.handle}`} passHref>
        <LinkWrapper data-gtm-name="product-card-anchor" onClick={handleProductCardClick}>
          {isPetition && <PetitionTitle>🚀 Petition</PetitionTitle>}
          <Title data-testid="product-card-title" invertColor={invertColor}>
            {product.title}
          </Title>
          {stage !== ProductStage.Demo && (
            <>
              <ProductDetailWrapper
                invertColor={invertColor}
                isComingSoon={stage === ProductStage.ComingSoon}
                isPetition={isPetition}
              >
                {metafields.creator ? `By ${metafields.creator}` : ''}
                {/* GITD 2024 */}
                {(isEvergreen || isHolidayHideawayProduct(product.id)) && !isSoldOut && (
                  <ShippingAvailabilityWrapper>
                    📦
                    <ShippingAvailability>Ready-to-ship!</ShippingAvailability>
                  </ShippingAvailabilityWrapper>
                )}
              </ProductDetailWrapper>
              {/* GITD 2024 */}
              {shouldShowProgressBar(stage, isEvergreen) && !isHolidayHideawayProduct(product.id) && (
                <StatsWrapper data-testid="product-card-funded-bar-wrapper">
                  <ProgressBar isGradient={isGradient} percentage={percentage} funded={funded} height={height} />
                  <Stats>
                    <StatsText data-testid="product-card-stats-text" invertColor={invertColor}>
                      {getStatsText()}
                    </StatsText>
                    {isEligibleForTimer(product) && renderDateStats(stage, invertColor, metafields)}
                  </Stats>
                </StatsWrapper>
              )}
              {(stage === ProductStage.ComingSoon || stage === ProductStage.Failed) && (
                <>{isEligibleForTimer(product) && renderDateStats(stage, invertColor, metafields)}</>
              )}
            </>
          )}
        </LinkWrapper>
      </Link>
    </Container>
  );
};

export default ProductCard;
