import React from 'react';
import styled from 'styled-components';
import { Theme } from '@makeship/core';
import { Caption } from '../Typography';
import { BadgeText } from '../../types/common';

export type BadgeProps = {
  text: BadgeText;
  isActive: boolean;
  className?: string;
  customBadgeBackgroundColor?: string;
};

const getBadgeBackgroundColor = (
  theme: Theme,
  customBadgeBackgroundColor: string | undefined,
  badgeText: BadgeText,
) => {
  if (customBadgeBackgroundColor) {
    return customBadgeBackgroundColor;
  }
  if (badgeText === BadgeText.Sale) {
    return theme.colors.error;
  }
  if (badgeText === BadgeText.SoldOut) {
    return theme.colors.gitdPink;
  }
  if (badgeText === BadgeText.LowStock) {
    return theme.colors.lowStock;
  }
  return theme.colors.accentLight;
};

const Container = styled.div<{
  customBadgeBackgroundColor: string | undefined;
  badgeText: BadgeText;
}>`
  padding: 4px 12px;
  background-color: ${({ theme, customBadgeBackgroundColor, badgeText }) =>
    getBadgeBackgroundColor(theme, customBadgeBackgroundColor, badgeText)};
  border-radius: 45px;
  text-transform: ${({ badgeText }) =>
    badgeText === BadgeText.LowStock || badgeText === BadgeText.GoalNotReached || badgeText === BadgeText.GoalReached
      ? 'capitalize'
      : 'uppercase'};
  display: flex;
  align-items: center;
  justify-content: center;
  ${Caption} {
    color: ${({ theme, badgeText }) => {
      if (badgeText === BadgeText.Sale || badgeText === BadgeText.LowStock || badgeText === BadgeText.SoldOut) {
        return theme.colors.neutral1;
      }
      return theme.colors.neutral7;
    }};
`;

const Badge: React.FC<BadgeProps> = ({ text, isActive, className, customBadgeBackgroundColor }: BadgeProps) =>
  isActive ? (
    <Container
      className={className}
      data-testid="pill-badge"
      customBadgeBackgroundColor={customBadgeBackgroundColor}
      badgeText={text}
    >
      <Caption data-testid="pill-badge-text">{text}</Caption>
    </Container>
  ) : (
    <></>
  );

export default Badge;
