import React from 'react';
import ReactCountdown, { CountdownRenderProps } from 'react-countdown';

type CountdownProps = {
  date: string;
  expanded?: boolean;
};

const Countdown: React.FC<CountdownProps> = ({ date, expanded }: CountdownProps) => {
  const renderer = ({ days, hours, minutes, seconds, completed }: CountdownRenderProps) => {
    if (completed) {
      return <span>Ended</span>;
    }

    if (!days && !hours) {
      return (
        <span>
          {minutes}m{expanded && ` ${seconds}s`} left
        </span>
      );
    }

    if (!days) {
      return (
        <span>
          {hours}h{expanded && ` ${minutes}m`} left
        </span>
      );
    }

    return (
      <span>
        {days}d{expanded && ` ${hours}h`} left
      </span>
    );
  };

  return <ReactCountdown date={new Date(date)} renderer={renderer} />;
};

export default Countdown;
